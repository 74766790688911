import axios from "axios";
import store from "@/store/index";
import { Notification, MessageBox } from "element-ui";
import { getToken } from '@/utils/storage';
import { message } from '@/utils/resetMessage';

axios.defaults.baseURL = window.baseUrl
axios.defaults.timeout = 10000
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.withCredentials = true;

//对外接口
export function request({ url, params, method,timeout }) {
  if (!method) {
    method = 'post'
  }
  if(!timeout){
    timeout = 10000
  }
  if (method === 'get') {
    return get(url, params)
  } else if (method === 'post') {
    return post(url, params,timeout)
  }
}

//封装get方法
function get(url, params) {
  return new Promise((resolve, reject) => {
    axios.get(url, params).then(res => {
      if (res.code == constants.interfaceResult.success) {
        resolve(res.data);
      } else {
        if (getToken() && res.msg !== '') {
          message.error({
            message: res.msg,
            showClose: true,
            duration: 2000,
            offset: 100
          });
        }
        reject(res.msg);
      }
    }).catch(err => {
        reject(err.msg)
      })
  })
}

//封装post方法
function post(url, params,timeout) {
  return new Promise((resolve, reject) => {
    axios.post(url, params,{timeout:timeout}).then(res => {
      if (res.code == constants.interfaceResult.success) {
        resolve(res.data)
      } else {
        if (getToken() && res.msg !== '') {
          message.error({
            message: res.msg,
            showClose: true,
            duration: 2000,
            offset: 100
          });
        }
        reject(res.msg);
      }
    }).catch(err => {
        reject(err);
      })
  })
}

// request拦截器
axios.interceptors.request.use(
  config => {
    if (getToken()) {
      config.headers["Authorization"] = getToken(); // 让每个请求携带自定义token
    }
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

// response 拦截器
axios.interceptors.response.use(
  response => {
    const code = response.status
    if (code < 200 || code > 300) {
      Notification.error({
        title: response.message
      })
      return Promise.reject('error')
    } else {
      return response.data
    }
  },
  error => {
    let code = 0;
    try {
      code = error.response.data.status
    } catch (e) {
      if (error.toString().indexOf('Error: timeout') !== -1) {
        message.error({
          message: '网络请求超时',
          duration: 2000,
          offset: 80
        })
        console.log(error);
        return Promise.reject(error)
      }
      if (error.toString().indexOf('Error: Network Error') !== -1) {
        message.error({
          message: '网络请求错误',
          duration: 2000,
          offset: 80
        })
        return Promise.reject(error)
      }
    }
    if (code == '401') {
      window.globalRouter.push({ path: "/login" });
    } else if (code == '403') {
      MessageBox.confirm(
        "登录状态已过期，您可以继续留在该页面，或者重新登录",
        "系统提示",
        {
          confirmButtonText: "重新登录",
          cancelButtonText: "取消",
          type: "warning"
        }
      ).then(() => {
        window.globalRouter.push({ path: "/login" });
      });
    }
    else if (
      error.response.config.url.indexOf("login") > -1 &&
      code == '400'
    ) {
      //登录失败时，不给弹出提示
    } else {
      const errorMsg = error.response.data.message;
      if (errorMsg !== undefined) {
        message.error({
          message: errorMsg,
          duration: 2000,
          offset: 80
        });
      }
    }
    return Promise.reject(error);
  }
);
